import { createStore } from 'vuex'
import axios from "axios";
import router from '../router'
import { createCommentVNode } from "vue";

export default createStore({
    state: {
        jwt: localStorage.getItem('t'),
        endpoints: {
            obtainJWT: 'https://avote-backend.maximedenis.com/auth/obtain_token',
            refreshJWT: 'https://avote-backend.maximedenis.com/auth/refresh_token'
        },
        users: [],
        user: {
            memberships: [],
        },
        loadingUser: true,
        player: {},
        team: {
            id: 5
        },
        next_game: {},
        next_games: [],
        last_games: [],
        next_events: [],
        last_events: [],
        isAuthenticated: false,
        token: '',
        isLoading: false,
        seasons: [],
        active: 'home',
        teams: [],
        poll: {},
        poll_result: [],
        errors: [],
        memberships: [],
        events: [],
        event: {},
        next_event: {},
        last_event: {},
        rules: {},
        teamStats: {
            games: 1,
            wins: 0,
            lost: 0,
            draw: 0,
            scored: 0,
            conceded: 0,
            point: 0,
            goal_scorers: []
        },
        vote: {},
        status: {}
    },
    getters: {
        isSuperuser: (state) => () => {
            return state.user.is_staff
        },
        isPlayer: (state) => (team_id) => {
            let membership = state.user?.memberships?.find(membership => membership.team.id === parseInt(team_id))
            let role
            if (membership) {
                role = membership.role
            } else {
                role = 'supporter'
            }

            return role.includes('player')
        },
        isSupporter: (state) => (team_id) => {
            let membership = state.user?.memberships?.find(membership => membership.team.id === parseInt(team_id))
            let role = membership.role
            return role.includes('supporter')
        },
        isStaff: (state) => (team_id) => {
            if (state.user.is_staff) {
                return true
            } else if (!state.user.memberships.length) {
                return false
            }
            let membership = state.user?.memberships?.find(membership => membership.team.id === parseInt(team_id))
            let role = membership.role
            return role.includes('staff')
        },
        isStaffOrAdmin: (state) => (team_id) => {
            if (state.user.is_staff) {
                return true
            } else if (!state.user.memberships.length) {
                return false
            }
            let membership = state.user?.memberships?.find(membership => membership.team.id === parseInt(team_id))
            let role = membership.role
            return role.includes('staff') || role.includes('admin')
        },
        isTeamAdmin: (state) => (team_id) => {
            if (state.user.is_staff) {
                return true
            } else if (!state.user.memberships.length) {
                return false
            }
            let memberships = state.user?.memberships?.filter(membership => membership.team.id === parseInt(team_id) && (membership.role.includes('staff') || membership.role.includes('admin')))
            return memberships.length > 0
        },
        isATeamAdmin: (state) => () => {
            let memberships = state.user?.memberships?.filter(membership => membership.role.includes('staff') || membership.role.includes('admin'))
            return memberships.length > 0
        },
        admin_membership: (state) => () => {
            return state.user?.memberships?.filter(membership => membership.role.includes('staff') || membership.role.includes('admin'))
        },
        fav_team: (state) => () => {
            if (state.user.memberships.length) {
                const membership = state.user?.memberships?.find(membership => membership.favorite)
                if (membership !== undefined) {
                    return membership.team
                } else {
                    return state.user?.memberships[0].team
                }
            } else {
                return null
            }
        }
    },
    mutations: {
        initializeStore(state) {
            if (localStorage.getItem('t')) {
                state.jwt = localStorage.getItem('t')
                state.isAuthenticated = true
            } else {
                state.token = ''
                state.isAuthenticated = false
            }
        },
        async login(state, path) {
            await axios.get("/api/users/me/")
                .then(async response => {
                    console.log('resp', response.data)
                    state.user = response.data
                    state.isAuthenticated = true
                    state.isAdmin = response.data.is_staff
                    if (path !== undefined) {
                        await router.push(path)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        logout(state) {
            state.user = {}
            state.team = {}
            state.isAuthenticated = false
            state.isAdmin = false
        },
        setIsLoading(state, status) {
            state.isLoading = status
        },
        setUser(state, user) {
            state.user = user
            state.loadingUser = false;
        },
        setUsers(state, users) {
            state.users = users
        },
        setActive(state, active) {
            state.active = active
        },
        setIsAuthenticated(state, value) {
            state.isAuthenticated = value
        },
        setIsAdmin(state, value) {
            state.isAdmin = value
        },
        setPlayer(state, user) {
            state.player = user
        },
        setTeam(state, team) {
            state.team = team
        },
        setTeams(state, teams) {
            state.teams = teams
        },
        setSeasons(state, seasons) {
            state.seasons = seasons
        },
        setPastEvents(state, events) {
            state.last_events = events
        },
        setNextEvents(state, events) {
            state.next_events = events
        },
        setPoll(state, poll) {
            state.poll = poll
        },
        setPollResult(state, results) {
            state.poll_result = results
        },
        updateToken(state, newToken) {
            localStorage.setItem('t', newToken);
            state.jwt = newToken;
        },
        removeToken(state) {
            localStorage.removeItem('t');
            state.jwt = null;
        },
        addToErrors(state, error) {
            state.errors.push(error)
        },
        resetErrors(state) {
            state.errors = []
        },
        removeError(state, error) {
            const index = state.errors.indexOf(error);
            state.errors.splice(index, 1);
        },
        setMemberships(state, memberships) {
            state.memberships = memberships
        },
        setEvents(state, events) {
            state.events = events
        },
        setEvent(state, event) {
            state.event = event
        },
        setNextEvent(state, event) {
            state.next_event = event
        },
        setLastEvent(state, event) {
            state.last_event = event
        },
        setRules(state, rules) {
            state.rules = rules
        },
        setTeamStat(state, stats) {
            state.teamStats = stats
        },
        setVote(state, vote) {
            state.vote = vote
        },
        setStatus(state, status) {
            state.status = status
        }
    },
    actions: {
        async login(context, path) {
            await axios.get("/api/users/me/")
                .then(async response => {
                    await context.commit('setUser', response.data)
                    await context.commit('setIsAuthenticated', true)
                    await context.commit('setIsAdmin', response.data.is_staff)
                    if (path !== undefined) {
                        await router.push(path)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async signIn(context, formData) {
            context.commit('resetErrors')
            axios.defaults.headers.common.Authorization = ""
            await axios
                .post("/auth/token/login/", formData)
                .then(async response => {
                    await context.commit('setIsAuthenticated', true)
                    const token = response.data.auth_token
                    await context.commit('updateToken', token)
                    axios.defaults.headers.common.Authorization = "Token " + token
                    await context.commit('login', { name: 'home' })
                })
        },
        async signUp(context, formData) {
            context.commit('resetErrors')
            axios.defaults.headers.common.Authorization = ""
            await axios
                .post("/auth/users/", formData)
                .then(async response => {
                    await context.dispatch('signIn', {
                        email: formData.email,
                        password: formData.password
                    })
                })
        },
        async createUser(context, formData) {
            context.commit('resetErrors')
            await axios
                .post("/auth/users/", formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async resetPwd(context, formData) {
            context.commit('resetErrors')
            await axios
                .post("/auth/users/reset_password_confirm/", formData)
                .then(async response => {
                    await router.push({ name: 'login' })
                })
                .catch(error => {
                    console.log(error)
                })
        },
        obtainToken(username, password) {
            const payload = {
                username: username,
                password: password
            }
            axios.post(this.state.endpoints.obtainJWT, payload)
                .then((response) => {
                    this.commit('updateToken', response.data.token);
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        refreshToken() {
            const payload = {
                token: this.state.jwt
            }
            axios.post(this.state.endpoints.refreshJWT, payload)
                .then((response) => {
                    this.commit('updateToken', response.data.token)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        inspectToken() {
            const token = this.state.jwt;
            if (token) {
                const decoded = jwt_decode(token);
                const exp = decoded.exp
                const orig_iat = decode.orig_iat
                if (exp - (Date.now() / 1000) < 1800 && (Date.now() / 1000) - orig_iat < 628200) {
                    this.dispatch('refreshToken')
                } else if (exp - (Date.now() / 1000) < 1800) {
                    // DO NOTHING, DO NOT REFRESH
                } else {
                    // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
                }
            }
        },
        async getUser(context, user_id) {
            await axios
                .get(`/api/users/${user_id}/`)
                .then(async response => {
                    await context.commit('setPlayer', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getUsers(context) {
            await axios.get(`/api/users/`)
                .then(response => {
                    context.commit('setUsers', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async getTeam(context, team_id) {
            axios
                .get(`/api/teams/${team_id}/`)
                .then(response => {
                    context.commit('setTeam', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getTeamMemberships(context, team_id) {
            await axios
                .get(`/api/teams/${team_id}/memberships/`)
                .then(response => {
                    context.commit('setMemberships', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getPastEvents(context) {
            context.commit('setPastEvents', [])
            await axios.get(`/api/my-past-events/`)
                .then(async response => {
                    context.commit('setPastEvents', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async getNextEvents(context) {
            context.commit('setNextEvents', [])
            await axios.get(`/api/my-next-events/`)
                .then(async response => {
                    context.commit('setNextEvents', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async updateEvent(context, formData) {
            await axios
                .put(`/api/events/${formData.id}/`, formData)
                .then(response => {
                    context.commit('setEvent', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async deleteEvent(context, id) {
            await axios
                .delete(`/api/events/${id}/`)
                .then(response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getTeams(context) {
            await axios.get(`/api/teams/`)
                .then(response => {
                    context.commit('setTeams', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async createTeamRule(context, team_id) {
            const formData = {
                team_id: team_id
            }
            await axios
                .post(`/api/rules/`, formData)
                .then(() => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createTeam(context, formData) {
            await axios
                .post("/api/teams/", formData)
                .then(async response => {
                    await context.dispatch('createTeamRule', response.data.id)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updateTeam(context, formData) {
            await axios
                .put(`/api/teams/${formData.id}/`, formData)
                .then(async response => {
                    await context.commit('setTeam', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createLicense(context, formData) {
            await axios
                .post("/api/licences/", formData)
                .then(async response => {
                    await context.dispatch('getTeam', formData.team_id)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async editLicense(context, formData) {
            await axios.put(`/api/licences/${formData.id}/`, formData)
                .then(() => { })
                .catch(err => { console.log(err) })
        },
        async deleteLicence(context, formData) {
            await axios
                .delete(`/api/licences/${formData.license_id}/`)
                .then(async response => {
                    await context.dispatch('getTeam', formData.team_id)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createMembership(context, formData) {
            await axios
                .post(`/api/memberships/`, formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updateMembership(context, formData) {
            await axios
                .put(`/api/memberships/${formData.id}/`, formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async deleteMembership(context, membership_id) {
            await axios
                .delete(`/api/memberships/${membership_id}/`)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updateUser(context, formData) {
            await axios
                .put(`/api/users/${formData.id}/`, formData)
                .then(async response => {
                    context.commit('setPlayer', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async deleteUser(context, user_id) {
            await axios
                .delete(`/api/users/${user_id}/`)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async storeResult(context, id) {
            await axios
                .post(`/api/events/${id}/result/`)
                .then(response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getPoll(context, id) {
            await axios
                .get(`/api/polls/${id}/`)
                .then(async response => {
                    await context.commit('setPoll', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getPollResult(context, game_id) {
            await axios
                .get(`/api/polls/${game_id}/results/`)
                .then(async response => {
                    await context.commit('setPollResult', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updateResult(context, result) {
            await axios
                .put(`/api/results/${result.id}/`, result)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async shuffleVotes(context, poll_id) {
            await axios
                .post(`/api/polls/${poll_id}/random/`)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createSeason(context, formData) {
            await axios
                .post("/api/seasons/", formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getSeasons(context) {
            await axios.get(`/api/seasons/`)
                .then(response => {
                    context.commit('setSeasons', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async deleteSeason(context, id) {
            await axios.delete(`/api/seasons/${id}/`)
                .then(response => {
                    context.dispatch('getSeasons')
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async createEvent(context, formData) {
            await axios
                .post("/api/events/", formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getEvents(context) {
            await axios
                .get("/api/events/")
                .then(async response => {
                    await context.commit('setEvents', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getEvent(context, id) {
            await axios
                .get(`/api/events/${id}/`)
                .then(async response => {
                    await context.commit('setEvent', response.data)
                    return response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async addPlayerToConvoc(context, { id, formData }) {
            await axios
                .put(`/api/convoc/${id}/add/`, formData)
                .then(async response => {
                    await context.dispatch('getEvent', context.state.event.id)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async removePlayerToConvoc(context, { id, formData }) {
            await axios
                .put(`/api/convoc/${id}/remove/`, formData)
                .then(async response => {
                    await context.dispatch('getEvent', context.state.event.id)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async sendConvoc(context, { id, formData }) {
            await axios
                .put(`/api/convoc/${id}/send/`, formData)
                .then(async response => {
                    await context.dispatch('getEvent', context.state.event.id)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getHomeEvents(context) {
            await axios
                .get(`/api/home-events/`)
                .then(async response => {
                    await context.commit('setNextEvent', response.data.next)
                    await context.commit('setLastEvent', response.data.last)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createAvailability(context, formData) {
            await axios.post('api/availabilities/', formData)
                .then(response => {
                    // context.dispatch('getEvents')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updateAvailability(context, formData) {
            await axios.put(`api/availabilities/${formData.id}/`, formData)
                .then(response => {
                    // context.dispatch('getEvents')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async markPresent(context, { event, status, user }) {
            let obj = event.availabilities.find(o => o.user.id === user.id)
            let formData
            if (obj) {
                // update
                obj.status = status
                await context.dispatch('updateAvailability', obj)
            } else {
                // create
                formData = {
                    user: user.id,
                    event: event.id,
                    status: status
                }
                await context.dispatch('createAvailability', formData)
            }
        },
        async getTeamRules(context, id) {
            await axios.get(`/api/teams/${id}/rules/`)
                .then(async response => {
                    context.commit('setRules', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async updateCategory(context, formData) {
            await axios
                .put(`/api/categories/${formData.id}/`, formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createCategory(context, formData) {
            await axios
                .post("/api/categories/", formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createRule(context, formData) {
            await axios
                .post(`/api/rules/`, formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createElected(context, formData) {
            await axios
                .post(`/api/elected/`, formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updateElected(context, formData) {
            await axios
                .put(`/api/elected/${formData.id}/`, formData)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async deleteCategory(context, id) {
            await axios
                .delete(`/api/categories/${id}/`)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async deleteElected(context, id) {
            await axios
                .delete(`/api/elected/${id}/`)
                .then(async response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getTeamStats(context, id) {
            await axios.get(`/api/team/${id}/stats/`)
                .then(response => {
                    context.commit('setTeamStat', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async updateReader(context, formData) {
            await axios
                .post(`api/polls/reader/`, formData)
                .then(async response => {
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async switchVote(context, { id, formData }) {
            await axios
                .put(`api/polls/${id}/`, formData)
                .then(async response => {
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async deleteVotes(context, id) {
            await axios.put(`/api/polls/${id}/delete/`)
                .then(async response => {
                })
                .catch(e => {
                    this.errors.push(e)
                    console.log(e)
                })
        },
        async createVote(context, id) {
            await axios
                .post(`/api/events/${id}/votes/me/`)
                .then(response => {
                    context.commit('setVote', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async submitVote(context, { id, formData }) {
            await axios
                .post(`/api/votes/${id}/submit/`, formData)
                .then(response => {
                    context.commit('setVote', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updateVote(context, { id, formData }) {
            await axios.put(`api/votes/${id}/update/`, formData)
                .then(response => {
                    context.commit('setVote', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async editVote(context, { id, formData }) {
            await axios.put(`api/votes/${id}/`, formData)
                .then(response => {
                    context.commit('setVote', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getVote(context, id) {
            await axios
                .get(`/api/votes/${id}/`)
                .then(response => {
                    context.commit('setVote', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getStatus(context, id) {
            await axios
                .get(`/api/events/${id}/status/`)
                .then(response => {
                    context.commit('setStatus', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async userToTeam(context, { id, formData }) {
            await axios.put(`/api/teams/add_user/${id}/`, formData)
                .then(() => {
                })
                .catch(async e => {
                    this.errors.push(e)
                    console.log(e)
                })
        },
        async usersToTeam(context, formData) {
            await axios.put(`/api/teams/add_users/`, formData)
                .then(() => {
                })
                .catch(async e => {
                    this.errors.push(e)
                    console.log(e)
                })
        },
        async getMyVote(context, id) {
            await axios
                .get(`/api/getmyvote/${id}/`)
                .then(response => {
                    context.commit('setVote', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async updatePlayer(context, formData) {
            await axios.put(`api/players/${formData.id}/`, formData)
                .then(async response => {
                    await context.dispatch('getVote', response.data.vote)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getNextVote(context, id) {
            await axios
                .post(`/api/votes/${id}/next/`)
                .then(response => {
                    context.commit('setVote', response.data)
                })
        },
        async getPreviousVote(context, id) {
            await axios
                .post(`/api/votes/${id}/previous/`)
                .then(response => {
                    context.commit('setVote', response.data)
                })
        },
        async endVote(context, id) {
            await axios
                .post(`/api/votes/${id}/close/`)
                .then(response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createScorer(context, formData) {
            await axios
                .post(`/api/scorers/`, formData)
                .then(response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async deleteScorer(context, id) {
            await axios
                .delete(`/api/scorers/${id}/`,)
                .then(response => {
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
    modules: {
    }
})
